import {
  DyteProvider,
  useDyteClient,
  useDyteMeeting,
  useDyteSelector,
} from '@dytesdk/react-web-core';
import {
  DyteParticipantTile,
  DyteNameTag,
  DyteAvatar,
  DyteAudioVisualizer,
} from '@dytesdk/react-ui-kit';
import { BreakoutRoomsManager } from '@dytesdk/ui-kit';

import { useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Button, Spinner } from 'reactstrap';
import useSocketConnection from 'Components/Hooks/useSocketConnection';
import hand from 'assets/images/svg/hand.svg';
import speakerOff from 'assets/images/svg/inClass/speaker_off.svg';
import speaker from 'assets/images/svg/inClass/speaker.svg';
import './video.scss';

const Component = ({ loading, forStudent }) => {
  const { meeting } = useDyteMeeting();
  const socket = useSocketConnection();
  const [handRaised, setHandRaised] = useState({});
  const tutorAudioRef = useRef(null);
  const participantAudioRef = useRef(null);
  const [volume, setVolume] = useState(1);
  const [isSpeakerMuted, setIsSpeakerMuted] = useState(false);
  const [isMuted, setIsMuted] = useState(true);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (socket) {
      socket.on('handRaiseToClient', (data) => {
        const parsedData = JSON.parse(data);
        setHandRaised((prev) => ({ ...prev, ...parsedData }));
      });
      socket.on('toggleTutorAudioInHuddleToClient', (data) => {
        // doing ! because there in tutor its mic=true and same event i am passing here here i am doing audioMute=flase
        setIsMuted(!data[searchParams.get('studentMeetingId')]);
      });
    }
    return () => {
      socket?.removeAllListeners('handRaiseToClient');
      socket?.removeAllListeners('toggleTutorAudioInHuddleToClient');
    };
  }, [socket, searchParams]);

  const participants = useDyteSelector((meeting) =>
    meeting?.participants?.joined.toArray(),
  );

  const tutors = useMemo(
    () =>
      participants.filter((participant) => {
        return participant?.presetName === 'group_call_host';
      }),
    [participants],
  );

  const activeParticipants = useDyteSelector((meeting) =>
    meeting?.participants?.active?.toArray(),
  );

  useEffect(() => {
    const tutorAudioElement = tutorAudioRef.current;
    const participantAudioElement = participantAudioRef.current;

    if (tutorAudioElement) {
      const tutorTracks = tutors
        .filter((tutor) => tutor?.audioTrack)
        .map((tutor) => tutor.audioTrack);

      if (tutorTracks.length > 0) {
        try {
          tutorAudioElement.pause();
          tutorAudioElement.srcObject = new MediaStream(tutorTracks);
        } catch (error) {
          console.error('Error setting tutor srcObject:', error);
        }
      }
    }

    if (participantAudioElement) {
      const participantTracks = activeParticipants
        .filter((activeSpeaker) => activeSpeaker?.audioTrack)
        .map((activeSpeaker) => activeSpeaker.audioTrack);

      if (participantTracks.length > 0) {
        try {
          participantAudioElement.pause();
          participantAudioElement.srcObject = new MediaStream(
            participantTracks,
          );
        } catch (error) {
          console.error('Error setting participant srcObject:', error);
        }
      }
    }
  }, [activeParticipants, tutors]);

  const toggleSpeaker = () => {
    setIsSpeakerMuted((prevIsMuted) => {
      const newIsMuted = !prevIsMuted;
      return newIsMuted;
    });
  };

  const handleVolumeChange = (e) => {
    const newVolume = e.target.value;
    setVolume(newVolume);
    if (isSpeakerMuted) {
      toggleSpeaker();
    }
    if (tutorAudioRef.current) {
      tutorAudioRef.current.volume = newVolume;
    }
    if (participantAudioRef.current) {
      participantAudioRef.current.volume = newVolume;
    }
  };

  return (
    <>
      {loading ? (
        <div className="vh-100 vw-100 d-flex justify-content-center align-items-center">
          <Spinner />
        </div>
      ) : (
        <>
          {forStudent ? (
            <>
              {tutors.map((tutor) => (
                <DyteParticipantTile
                  participant={tutor}
                  meeting={meeting}
                  key={tutor?.id}
                  className="vw-100 vh-100"
                >
                  <DyteAvatar participant={tutor} size="md" />
                  <DyteNameTag
                    participant={tutor}
                    meeting={meeting}
                  ></DyteNameTag>
                </DyteParticipantTile>
              ))}
              <audio
                id="tutorAudio"
                ref={tutorAudioRef}
                autoPlay
                muted={isMuted}
              />
            </>
          ) : (
            <div className="vh-100 vw-100 d-flex gap-2 justify-content-center align-items-center">
              {' '}
              {participants.map((participant) => (
                <DyteParticipantTile
                  participant={participant}
                  meeting={meeting}
                  key={participant?.id}
                  className="w-100 h-100"
                >
                  {handRaised?.[participant?.customParticipantId] && (
                    <img
                      className="position-absolute hand"
                      src={hand}
                      alt="hand"
                    />
                  )}
                  <DyteAvatar participant={participant} size="md" />
                  <DyteNameTag participant={participant} meeting={meeting}>
                    <DyteAudioVisualizer
                      participant={participant}
                      slot="start"
                    />
                  </DyteNameTag>
                </DyteParticipantTile>
              ))}
              <audio
                ref={participantAudioRef}
                autoPlay
                muted={isSpeakerMuted}
              />
              <div className="huddle-audio-controls d-flex flex-column align-items-center rounded bg-light p-1">
                <input
                  id="volume"
                  type="range"
                  min="0"
                  max="1"
                  step="0.01"
                  value={volume}
                  onChange={handleVolumeChange}
                  className="huddle-volume-slider"
                />
                <Button
                  onClick={toggleSpeaker}
                  className="p-1 mt-1 rounded-circle huddle-speaker-button"
                >
                  <img
                    src={isSpeakerMuted ? speakerOff : speaker}
                    alt="speaker"
                    height="20"
                    width="20"
                  />
                </Button>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

function Video() {
  const [loading, setLoading] = useState(true);
  const [meeting, initMeeting] = useDyteClient();
  const [searchParams] = useSearchParams();
  const assitantId = searchParams.get('assitantId');
  const token = searchParams.get('token');
  const meetingId = searchParams.get('meeting');
  const forStudent = searchParams.get('forStudent');

  useEffect(() => {
    const handler = () => {
      setLoading(false);
    };
    meeting?.connectedMeetings?.on('meetingChanged', handler);
    return () =>
      meeting?.connectedMeetings?.removeListener('meetingChanged', handler);
  }, [meeting]);

  useEffect(() => {
    initMeeting({
      authToken: token,
      defaults: {
        audio: false,
        video: false,
      },
    }).then(async (meet) => {
      if (!meet) return;
      if (meet.self.roomJoined) return;
      meet
        .join()
        .then(async () => {
          if (!meetingId) {
            setLoading(false);
            return;
          }
          if (meet && meetingId && assitantId) {
            if (meet.meta.meetingId !== meetingId) {
              const breakoutManager = new BreakoutRoomsManager();
              const state = await meet.connectedMeetings.getConnectedMeetings();
              breakoutManager.updateCurrentState(state);
              breakoutManager.assignParticipantsToMeeting(
                [assitantId],
                meetingId,
              );
              await breakoutManager.applyChanges(meet);
            } else {
              setLoading(false);
            }
          }
        })
        .catch((err) => console.log(err, 'error in joining room'));
    });
  }, []);

  return (
    <DyteProvider value={meeting}>
      <Component loading={loading} forStudent={forStudent} />
    </DyteProvider>
  );
}

export default Video;
